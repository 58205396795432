/* view service engineer */

.view-data p {
    background-color: #F09071;
    width: 50px;
    padding: 4px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
}

.view-data p .anticon {
    font-size: 12px;
    margin-right: 6px;
}

.view-data h6 {
    font-size: 12px;
    margin-top: 4px;
    cursor: pointer;
}

.view-data .atten {
    font-size: 11px;
    font-weight: 500;
    padding: 5px 15px !important;
    height: auto !important;
    border-radius: 4px;
    background-color: rgb(211, 211, 211);
    color: #000;
    cursor: pointer;
    margin-right: 10px;
    border: none;
}

.view-data .edit {
    font-size: 11px;
    font-weight: 500;
    padding: 5px 15px !important;
    height: auto !important;
    border-radius: 4px;
    background-color: rgb(192, 192, 254);
    color: blue;
    cursor: pointer;
    border: none;
}

.view-data-body h2 {
    font-size: 14px;
    font-weight: 600;
}

.view-data-body {
    padding-bottom: 10px;
    border-bottom: 2px solid #e0e0e0;
}

.view-data-body h3 {
    margin-top: 4px;
    font-size: 12px;
    color: #000;
}

.view-data-body label {
    font-size: 12px;
    font-weight: 500;
    color: #a9a8a8;
}

.ticket-history h2 {
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
}

.ant-steps-item-title {
    font-size: 13px;
}

.steps-part {
    overflow: auto;
    height: 350px;
    padding-top: 10px;
}

.ant-popover {
    width: auto !important;

}

.ant-popover button {
    background-color: blue;
    color: #fff;
    border: none;
    font-size: 12px;
    border-radius: 5px;
}

.ant-popover label {
    font-size: 14px;
    font-weight: 600 !important;
    padding-top: 5px;
}

.ant-popover-inner {
    /* border-radius: 20px !important; */
    /* min-height: 150px !important; */
}

.drawer-head-two {
    padding-bottom: 0px !important;
}

.ant-tabs-tab-btn {
    font-weight: 600 !important;
}

.steps-part {
    margin-top: 10px;
}

.steps-part p {
    font-size: 12px !important;
    padding-top: 3px !important;
    font-weight: 500;
    color: grey;
}

.status-bar h1 {
    font-size: 11px !important;
}

.status-bar h3 {
    font-size: 11px !important;
}

.star {
    width: 10px;
    margin-right: 5px;
}

.fixed-bottom-1 {
    position: absolute;
    bottom: 30px;
    width: 100%;
    background-color: #fff;
}

span.ant-radio+* {
    font-size: 12px !important;
    font-weight: 400px !important;
}

.red-1 {
    position: absolute;
    color: red;
    left: 90px;
}

.red-2 {
    position: absolute;
    color: red;
    left: 65px;
}

.red-3 {
    position: absolute;
    color: red;
    left: 135px;
}

.red-4 {
    position: absolute;
    color: red;
    left: 75px;
}

.red-5 {
    position: absolute;
    color: red;
    left: 105px;
}

.red-6 {
    position: absolute;
    color: red;
    left: 125px;
    margin-top: 10px;
}

.red-7 {
    position: absolute;
    color: red;
    left: 115px;
    top: 10px;
}

.red-8 {
    position: absolute;
    color: red;
    left: 45px;
    top: 98px;
}

.red-61 {
    position: absolute;
    color: red;
    left: 115px;
    margin-top: 10px;
}

.new-dra {
    padding-bottom: 0px !important;
}

/* view service engineer */