/* Side bar start */

.logo-head {
    padding: 15px;
    border-bottom: 1px solid #F4F4F4;
}

.logo {
    width: 85px !important;
}

.service-sidebar {
    background-color: #fff;
    height: 100vh;
    width: 100%;
    border-right: 1px solid #78777729;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.service-view-sidebar ul {
    padding: 25px;
    border-bottom: 1px solid #F4F4F4;
}

.service-view-sidebar ul li {
    list-style: none;
    padding: 5px 10px;
    font-size: 13px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "poppins" !important;
}

.service-view-sidebar ul li a {
    color: #7C7C7C;
    font-weight: 500;
    font-family: "poppins" !important;
}

.service-view-sidebar ul li {
    color: #7C7C7C;
    font-weight: 500;
    cursor: pointer;
    font-family: "poppins" !important;
}

.service-view-sidebar ul li a img {
    margin-right: 10px;
}

.service-view-sidebar ul li img {
    margin-right: 10px;
    cursor: pointer;
}

.service-view-sidebar .footer-ul {
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    bottom: 70px !important;
    width: 100%;
}

.service-view-sidebar .profile-ul {
    padding-top: 10px;
    padding-bottom: 15px;
    position: absolute;
    bottom: 0px !important;
}

.active {
    background-color: #F8F8F8;
    border-radius: 5px;
}

.active a {
    color: #000 !important;
    font-family: "poppins" !important;
}

.active a .anticon {
    color: #3157F6 !important;
}

.profile-ul p {
    font-size: 10px;
    margin-bottom: 0px;
    color: #7C7C7C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px !important;
}

.profile-ul p b {
    font-size: 11px;
    margin-bottom: 0px;
    color: #000;
    font-weight: 600;
}

::placeholder {
    font-size: 12px !important;
}

.ant-select-selection-placeholder {
    font-size: 12px !important;
}

.avatar-logo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

/* Side bar End */