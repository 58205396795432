.staff {
    min-width: 650px;
}

.create-staff {
    margin-top: -100px !important;
}

.create-staff Button {
    font-size: 12px
}

.create-staff .drawer-head {
    padding-bottom: 0px !important;
    font-size: 19px;
    font-weight: 600;
}

.create-staff .ant-input,
.ant-input-affix-wrapper {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

.create-staff .ant-input-prefix {
    font-size: 12px;
}

.create-staff .ant-form-vertical .ant-form-item-label {
    padding-bottom: 5px !important;
}

.create-staff .ant-form-vertical .ant-form-item-label label {
    font-weight: 500 !important;
}

.blue-line-code {
    color: blue !important;
}

.staff-one {
    position: absolute;
    left: 95px;
    color: red;
}

.staff-two {
    position: absolute;
    left: 135px;
    color: red;
}

.staff-three {
    position: absolute;
    left: 65px;
    color: red;
}

.staff-four {
    position: absolute;
    left: 90px;
    color: red;
}