.customer-pop-up {
    min-width: 650px !important;
}

.customer-pop-up .ant-modal-body {
    margin-top: 0px !important;
}

.customer-one {
    position: absolute;
    color: red;
    left: 210px;
}

.customer-two {
    position: absolute;
    color: red;
    left: 115px;
}

.customer-three {
    position: absolute;
    color: red;
    left: 115px;
}

.customer-four {
    position: absolute;
    color: red;
    left: 50px;
}

.customer-fours {
    display: none;
}

.customer-five {
    position: absolute;
    color: red;
    left: 35px;
}

.customer-fives {
    display: none;
}

.customer-six {
    position: absolute;
    color: red;
    left: 125px;
}

.customer-seven {
    position: absolute;
    color: red;
    left: 55px;
}

.customer-eight {
    position: absolute;
    color: red;
    left: 75px;
}

.customer-head {
    padding-bottom: 0px !important;
}

.map-link {
    color: grey;
    text-decoration: underline;
    font-size: 11px;
    position: absolute !important;
    top: 65px;
    right: 20px;
    cursor: no-drop;
}

.pop-up-check .ant-btn {
    font-size: 12px;
}

.ant-input-prefix {
    font-size: 12px;
    color: grey;
}