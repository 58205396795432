/* Login Page  start*/
.service-view-auth {
    background: url(../../../assets/backgrounds/auth_bg.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}

.sign-in {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 80vh;
    gap: 40px;
}


.sign-in .sign-carousel .people {
    width: 100%;
}

.sign-in .sign-carousel .peopledot {
    width: 40px;
}



.sign-in .sign-carousel h1 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 110%;
    letter-spacing: -0.05em;
    color: #283742;
}

.sign-in .sign-carousel h5 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #283742;
}


.sign-in .sign-form h5 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 15px;
    color: #283742;
}

.sign-in .sign-form p,
.sign-in .sign-form .ant-input-suffix {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
}

.sign-in .sign-form .ant-form-item-label label {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12.4px !important;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #161519;
}

.sign-in .sign-form .ant-input,
.sign-in .sign-form .ant-input-affix-wrapper {
    background: rgba(144, 145, 156, 0.08) !important;
    border-radius: 6px;
    border: none !important;
    font-family: 'DM Sans';
    font-size: 13px;
    height: 40px;
    padding: 0px 10px 0px 10px;
}

.new-pas .sign-form .ant-input,
.new-pas .sign-form .ant-input-affix-wrapper {
    background: rgba(144, 145, 156, 0.08) !important;
    border-radius: 6px;
    border: none !important;
    font-family: 'DM Sans';
    font-size: 13px;
    height: 40px;
    padding: 3px 10px 0px 10px;
}

.sign-in .sign-form .ant-input-affix-wrapper #sign_in_password,
.sign-in .sign-form .ant-input-affix-wrapper #service_info_domainName,
.sign-in .sign-form .ant-input-affix-wrapper #service_info_newPassword,
.sign-in .sign-form .ant-input-affix-wrapper #service_info_confirmPassword {
    margin-top: -4px;
    background: rgba(144, 145, 156, 0) !important;
    border-radius: 6px;
    border: none !important;
    font-family: 'DM Sans';
    font-size: 13px;
    height: 40px;
}

.sign-in .sign-form .ant-form-item {
    margin-bottom: 20px !important;
}

.ant-input-affix-wrapper-focused {
    font-family: 'DM Sans' !important;
    box-shadow: none !important;
}

.sign-in .sign-form .ant-btn {
    background: #3157F6;
    border-radius: 6px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
}

.sign-in .sign-form p a {
    color: #3157F6;
    font-weight: 600 !important;
}

.sign-in .sign-form .forg a {
    position: relative;
    top: -10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
}

.sign-in .sign-form #service_info_contactNumber {
    border-radius: 0px 6px 6px 0px !important;
}

.sign-in .sign-form .ant-input-group-addon,
.sign-in .sign-form .ant-input-group-wrapper-status-error .ant-input-group-addon {
    background: rgba(144, 145, 156, 0.08) !important;
    border-radius: 6px 0px 0px 6px !important;
    border: none !important;
    font-family: 'DM Sans';
    font-size: 13px;
    height: 40px;
    color: rgba(0, 0, 0, 0.5);
}

.sign-in .sign-form .service-btn {
    margin-top: 5px;
}

.sign-in .sign-form h3 {
    font-size: 20px;
    position: relative;
    top: -30px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
}

.sign-in .sign-form h3 span {
    /* color: #3157F6; */
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
}

.otp-head button,
.otp-head span {
    font-family: 'DM Sans';
    font-family: 'DM Sans';
    font-style: normal;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 13px !important;
    font-weight: 600;
}

#service_info_organizationDomainName {
    background-color: transparent !important;
    margin-left: -10px !important;
}

#sign_in_password {
    margin-left: -10px;
}

@media only screen and (max-width: 600px) {
    .service-view-auth {
        background: #f9f9f9;
    }

    .people {
        width: 100% !important;
        margin-left: 0px;
    }

    .sign-in {
        flex-direction: column;
        flex-direction: column-reverse;
        padding: 0px 20px 20px 20px;
        min-height: 110vh;
    }

    .sign-in .sign-carousel h1 {
        font-size: 25px;
    }

    .sign-in .sign-form {
        width: 100% !important;
    }
}


@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .sign-in .sign-form {
        min-width: 350px;
    }
}

/* Login Page End */





.login-popup h1 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 30px;
}

.mode {
    min-width: 800px !important;
}

.mode .ant-modal-body {
    padding: 14px;
}

.mode .ant-modal-body {
    margin-top: 0px !important;
}

.org .ant-btn {
    display: flex;
    gap: 10px;
    background: #D6D6D6;
    border-radius: 8px;
    height: 50px;
    padding-top: 12px;

}

.active .ant-btn {
    display: flex;
    gap: 10px;
    background: #FEBD2D;
    border-radius: 8px;
    border: 1px solid #FEBD2D;
}

.org .ant-btn:hover {
    display: flex;
    gap: 10px;
    background: #FEBD2D;
    border-radius: 8px;
    border: 1px solid #FEBD2D;
}

.org .ant-btn h2 {
    font-size: 15px !important;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
}

.org Button p {
    background-color: aqua;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    padding: 7px;
    margin-top: -4px;
    background: #fff;
    color: #666;
    text-align: center;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.add-org,
.add-org:hover {
    margin-top: 20px;
    background: #3157F6;
    border-radius: 6px;
    height: 40px;
    font-size: 12px;
    width: 140px;
    color: #fff;
    border: none;
}

.cancel-org,
.cancel-org:hover {
    margin-top: 20px;
    background: #7C7C7C;
    border-radius: 6px;
    height: 40px;
    font-size: 12px;
    width: 140px;
    color: #ffff;
    border: none;
}

.nameDomain {
    display: flex;
}

.pass input {
    margin-top: 0px !important;
}

.tag a {
    cursor: pointer;
    position: relative;
    left: -30px;
    color: #161519;
}

.star-one {
    position: absolute;
    color: red;
    margin-left: 160px;
}

.star-two {
    position: absolute;
    color: red;
    margin-left: 130px;
}

.star-three {
    position: absolute;
    color: red;
    margin-left: 110px;
}

.star-four {
    position: absolute;
    color: red;
    margin-left: 40px;
}

.star-five {
    position: absolute;
    color: red;
    margin-left: 100px;
}

.star-six {
    position: absolute;
    color: red;
    margin-left: 120px;
}

.star-seven {
    position: absolute;
    color: red;
    margin-left: 90px;
    margin-top: -1px;
}

.set-new input {
    padding: 15px 10px 10px 0px !important;
}

.star-1 {
    position: absolute;
    color: red;
    margin-left: 40px;
}

.star-2 {
    position: absolute;
    color: red;
    margin-left: 30px;
}