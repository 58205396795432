.new-drag.ant-upload-drag {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: relative;
    left: 35%;
    top: -10px;
}

.service-bt {
    background-color: blue;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    margin-top: 20px;
}

.service-btt {
    background-color: blue;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    position: absolute;
    bottom: -10px !important;
}

.new-drag .size_logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: -17px;
    margin-left: -1px;
}

.size_log {
    width: 100px;
    /* height: 100px; */
    /* border-radius: 0%; */
    /* margin-top: -17px;
    margin-left: -1px; */
}

.product-form .ant-form-item {
    margin-bottom: 15px !important;
}

.product-form .ant-form-item-label {
    line-height: 15px !important;
}

.ant-upload-list {
    display: none !important;
}

.edit-button {
    background-color: blue;
    color: #fff;
    font-size: 12px;
    margin-top: 140px;
    border-radius: 5px;
}

.cancel-button {
    background-color: #fff !important;
    border: 1px solid blue !important;
    color: blue;
    font-size: 12px;
    border-radius: 5px;
}

.price {
    border: 2px solid rgb(139, 220, 17);
    border-radius: 5px;
    font-size: 12px;
    margin-top: 8px;
    color: rgb(139, 220, 17);
}

.history-btn {
    color: blue;
    font-size: 11px;
    margin-top: 15px;
    cursor: pointer;
    text-decoration: underline;
}

.txt-rights {
    text-align: right;
    padding-right: 210px;
}

.product-table table tbody tr td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.product-table tbody tr td .s3-svg {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
}