@media screen and (max-width: 1440px) {
    .ticket-table {
        overflow-x: auto;
        margin-top: 10px;
        /* height: auto !important; */
    }

    .single-ticket-card,
    #scrollableDiv {
        overflow: auto;
        height: 560px !important;
        /* padding: 25px; */
    }

    .table-head-part {
        overflow-x: auto;
        height: 340px !important;
    }

    .dashboard-table tbody tr td {
        padding-top: 19px !important;
    }
}


@media only screen and (max-width: 600px) {
    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .auth-view,
    .mail-verify {
        padding: 30px !important;
    }

    .people {
        margin-left: -50px;
    }

    .auth-view h2,
    .auth-view p {
        margin-left: 0px !important;
    }

    .domain-check {
        padding: 30px 30px 230px 30px;
    }

    .mail-verify img {
        width: 100% !important;
    }

    .service-view-banner {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }

    .service-view-banner,
    .service-view-start {
        padding: 30px !important;
    }

    .service-view-start p {
        text-align: justify;
    }

    .service-view-banner .banner-img {
        width: 100%;
    }


    .banner-one {
        padding: 30px 0px 30px 30px !important;
    }

    .service-view-welcome .txt-left img {
        width: 100% !important;
    }

    .service-view-welcome {
        padding: 30px 30px 0px 30px !important;
    }

    .service-view-service {
        padding: 0px 30px 30px 30px !important;
    }

    .gap-gutter {
        gap: 0px !important;
        margin: 0px !important;
        padding: 0px 7px 10px 10px !important;
    }



    .mobile {
        text-align: center !important;
        padding-top: 10px !important;
    }

    .service-view-flag,
    .service-view-pad {
        padding: 30px !important;
    }

    .service-view-service {
        padding-top: 30px !important;
    }

    .service-view-title .title img {
        margin-top: -30px !important;
    }

    .service-view-title .title p {
        padding: 30px !important;
        text-align: justify;
    }

    .service-view-paragraph {
        padding: 0px 30px 30px 30px !important;
    }

    .flag-contact,
    .contact-card {
        padding: 30px 30px 0px 30px !important;
    }


    .service-view-white {
        padding: 0px 0px 30px 30px !important;
    }

    .service-view-white .text-card {
        padding: 30px 30px 0px 30px !important;
    }

    .service-view-paragraph p {

        text-align: justify;
    }

    .content {
        padding: 30px !important;
    }

    .service-view-header .txt-right .anticon {
        font-size: 30px;
        margin-top: 10px;
        border-radius: 4px;
    }

    .dra ul li {
        list-style: none !important;
        font-size: 17px;
        line-height: 50px;
        text-align: center;
        color: #000;
    }

    .dra ul li a {
        color: #000;
    }

    .mail-verify h3 {
        font-size: 22px !important;
    }

    .mail-verify h3 span {
        font-size: 22px !important;
    }

    .add-new-cust {
        margin-top: 0px !important;
    }












    .service-main-page {
        padding: 15px !important;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        position: relative !important;
    }

    .border-page {
        padding: 0px !important;
        width: 70px;
    }

    .service-main-title {
        padding-top: 10px !important;
    }

    .service-main-page h2 {
        text-align: center;
    }

    .service-main-title Button {
        width: 100% !important;
    }

    .loader img {
        width: 100% !important;
    }

    .table-head-part {
        overflow-x: auto;
        min-height: 200px !important;
    }

    .table-loading img {
        width: 100%;
        position: absolute;
        left: 25%;
        top: 42% !important;
    }








    .drawer-head {
        padding-bottom: 0px !important;
    }

    .view-data-body {
        margin-top: 10px !important;
    }

    .view-data button {
        width: 100%;
    }






    .web-logo {
        width: 80px !important;
    }

    .logo-mob {
        width: 100px !important;
    }

    .auth-header .logo {
        z-index: 99;
        position: relative;
        width: 100px;
        margin-left: -10px !important;
    }

    .inside {
        display: none;
    }

    .single-ticket-card {
        padding: 10px !important;
    }

    .licence-card {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .button-split .ant-btn {
        background-color: blue;
        color: #fff;
        border-radius: 20px;
        font-size: 12px;
        margin: 10px;
        margin-top: 0px;
        width: 120px;
        position: relative !important;
        bottom: 0px !important;
        left: 0px !important;
    }

    .licence-card .blue-back {
        margin-top: 0px !important;
    }
}

.logo-mob {
    width: 100px !important;
}