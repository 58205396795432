.add-new-cust {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 5px;
    color: blue;
    background-color: rgb(204, 204, 254);
    border: 1px solid rgb(204, 204, 254);
    font-weight: 500;
    margin-top: 30px;
}

.footer-btn {
    position: absolute;
    bottom: -20px;
    width: 100% !important;
    padding-bottom: 40px;
    background-color: #fff;
}

.head-draw {
    margin-top: -7px !important;
    margin-bottom: -12px !important;
}

.star-head {
    background-color: #c9d2f9;
    color: #3157F6 !important;
    /* width: 100px; */
    padding: 3px;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
}

.star-heads {
    background-color: #c9d2f9;
    border-radius: 5px;
    color: #3157f6 !important;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    max-width: 100px;
}

.star-head img,
.star-heads img {
    margin-right: 3px !important;
    width: 10px;
}

.add-new-cust:hover {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 5px;
    color: blue;
    background-color: #fff;
    border: 1px solid blue;
    font-weight: 500;
    margin-top: 30px;
}

.tic .ant-input {
    height: 33px !important;
}

.lines {
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 10px !important;
    margin-top: 3px;
}

.lin {
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 20px !important;
    margin-top: 5px;
    margin-bottom: 5px;
}

.tic textarea.ant-input {
    height: auto !important;
}

label {
    font-weight: 600 !important;
}

.button-create {
    font-size: 12px;
    background-color: #3157F6;
    border: 1px solid #3157F6;
    color: #fff;
    width: 140px;
    border-radius: 3px;
}

.button-create:hover {
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #3157F6;
    color: #3157F6;
    width: 140px;
    border-radius: 3px;
}


.button-create1 {
    font-size: 12px;
    background-color: #ad6dd5;
    border: 1px solid #ad6dd5;
    color: #fff;
    width: 140px;
    border-radius: 3px;
}

.button-create1:hover {
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ad6dd5;
    color: #ad6dd5;
    width: 140px;
    border-radius: 3px;
}

.button-cancel,
.button-cancel:hover {
    font-size: 12px;
    background-color: grey;
    border: 1px solid grey;
    color: #ffff;
    width: 140px;
    border-radius: 3px;
}

.comment-cancel,
.comment-cancel:hover {
    font-size: 12px;
    background-color: grey;
    border: 1px solid grey;
    color: #ffff;
    width: 100px;
    border-radius: 3px;
}

.engineer {
    font-size: 11px;
    background-color: #fff !important;
    border: 1px solid rgb(210, 210, 210) !important;
    color: #000 !important;
    width: 170px;
    border-radius: 3px;
}

.s-green .ant-input-disabled {
    background-color: #6FCF97;
    color: #fff;
    font-weight: 600;
    border: 1px solid #6FCF97 !important;
}

.scroll {
    overflow: auto;
    height: 500px;
    padding-bottom: 45px;
}

.sub-head {
    font-size: 13px;
    font-weight: 600;
    margin-top: -5px !important;
}



.assign-engineer h2 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
}

.assign-engineer p {
    font-size: 12px;
    color: grey;
}

.assign-engineer button {
    margin-top: 10px;
}

.engineer-btn-custom button {
    margin-top: 0px !important;
    margin-bottom: 10px;
}

.engineer-btn-custom .button-blue {
    margin-top: 20px !important;
}

.ant-tabs-tab-btn {
    font-size: 13px;
    margin-top: -5px;
}

.assign-engineer .ant-form-item {
    margin-bottom: 0px;
}

.button-grey {
    font-size: 12px;
    background-color: grey;
    border: 1px solid grey;
    color: #ffff;
    width: 140px;
    border-radius: 3px;
}

.button-grey:hover:hover {
    font-size: 12px;
    background-color: grey;
    border: 1px solid grey;
    color: #ffff;
    width: 140px;
    border-radius: 3px;
}

.button-blue {
    font-size: 12px;
    background-color: #3157F6;
    border: 1px solid #3157F6;
    color: #fff;
    width: 140px;
    border-radius: 3px;
}

.button-blue:hover {
    font-size: 12px;
    background-color: #3157F6;
    border: 1px solid #3157F6;
    color: #fff;
    width: 140px;
    border-radius: 3px;
}

.emp-btn {
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    padding-bottom: 0px !important;
    padding-top: 10px;
    width: 95%;
}


.footer-button {
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    padding-bottom: 25px;
    padding-top: 10px;
    width: 95%;
}

.footer-button img {
    width: 20px;
    padding-right: 10px;
}

.assign-engineer {
    background-color: #fff;
}


.hrss {
    width: 100%;
    border-bottom: 1px solid #a9a8a8;
    margin-top: 11px;
}

.no-data-bottom {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    left: 38%;
    top: 40%;
}

.fixed-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding-top: 20px;
}

.ticket-one {
    position: absolute;
    color: red;
    left: 125px;
}

.ticket-two {
    position: absolute;
    color: red;
    left: 75px;
}

.ticket-three {
    position: absolute;
    color: red;
    left: 120px;
}

.ticket-four {
    position: absolute;
    color: red;
    left: 95px;
}

.ticket-five {
    position: absolute;
    color: red;
    left: 130px;
}

.ticket-six {
    position: absolute;
    color: red;
    left: 122px;
}

.ticket-seven {
    position: absolute;
    color: red;
    left: 100px;
}

.ticket-eight {
    position: absolute;
    color: red;
    left: 90px;
}

.ticket-nine {
    position: absolute;
    color: red;
    left: 105px;
}

.map-link {
    color: grey;
    text-decoration: underline;
    font-size: 11px;
    position: absolute !important;
    top: 65px;
    right: 20px;
    cursor: no-drop;
}

.ant-drawer-content-wrapper .ant-input-affix-wrapper {
    max-height: 32px !important;
}

.contact-size input {
    margin-top: -4px !important;
    max-height: 30px;
}

.tic {
    padding-bottom: 30px;
}

.grey-select .ant-select-selector,
.grey-select .ant-select-selection-placeholder,
.grey-select .ant-select-arrow {
    background-color: rgb(160, 160, 160) !important;
    color: #fff;
}

.customer-one {
    position: absolute;
    color: red;
    left: 210px;
}

.customer-two {
    position: absolute;
    color: red;
    left: 115px;
}

.customer-three {
    position: absolute;
    color: red;
    left: 115px;
}

.customer-four {
    position: absolute;
    color: red;
    left: 50px;
}

.customer-fours {
    display: none;
}

.customer-five {
    position: absolute;
    color: red;
    left: 35px;
}

.customer-fives {
    display: none;
}

.customer-six {
    position: absolute;
    color: red;
    left: 125px;
}

.customer-seven {
    position: absolute;
    color: red;
    left: 55px;
}

.customer-eight {
    position: absolute;
    color: red;
    left: 75px;
}

.ant-select-selector {
    border-radius: 3px !important;
}

.css-b62m3t-container {
    position: absolute;
    top: -2px;
    height: 34px;
}

.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
    font-size: 12px !important;
}

.css-1jqq78o-placeholder {
    color: rgb(183, 183, 183) !important;
}

.css-1hb7zxy-IndicatorsContainer,
.css-1xc3v61-indicatorContainer,
.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.ticket-drawer .ant-drawer-body {
    padding: 30px !important;
}

.single-ticket-card .color-choice .ant-select-selector {
    background-color: transparent !important;
    color: #fff;
}

.grey-status .ant-select-selector {
    background-color: #909191 !important;
    color: #fff !important;
}