/*  Web Footer Start */
.footer-container {
    font-family: "DM Sans" !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #111013;
    min-height: 400px;
    padding: 40px;
    border-bottom: 0.2px solid rgba(144, 145, 156, 0.2);
}

.footer-container h1 {
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 105%;
    letter-spacing: -0.045em;
}

.footer-container h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 13.4px;
    line-height: 25px;
    letter-spacing: 0.015em;
    color: rgba(255, 255, 255, 0.75);
    border: none !important;
}

.footer-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.015em;
    color: rgba(255, 255, 255, 0.75);
    position: relative;
    top: 40px;
}

.footer-container .contact-button a {
    color: #fff;
    background-color: #3157f6;
    padding: 9px 20px;
    gap: 6px;
    border-radius: 5px;
    font-size: 15px;
    position: relative;
    top: -70px;
}

.contact-button a:hover {
    background-color: cadetblue;
}

.footer-container .contact-buttonss {
    color: #fff;
    background-color: #3157f6;
    padding: 9px 20px;
    gap: 6px;
    border-radius: 5px;
    font-size: 15px;
    position: relative;
    top: -70px;
    cursor: pointer;
}

.contact-buttonss:hover {
    background-color: cadetblue;
    cursor: pointer;
}


.icon-img {
    width: 10px;
    margin-top: -2px;
    margin-left: 5px;
}

.footer-container .line {
    position: absolute;
    border-bottom: 1px solid rgba(144, 145, 156, 0.2);
    width: 78%;
    margin-top: 80px;
}

/*  Web Footer End */

.main-footer {
    font-family: "DM Sans" !important;
    display: flex;
    justify-content: space-evenly;
    background-color: #161519;
    gap: 40px;
    min-height: 200px;
    padding: 60px 40px;
}

.main-footer h5 {
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #91929b;
    font-size: 13px;
}

.main-footer p {
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.75);
}


.main-footer p a {
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.75);
}

.main-footer .email {
    width: 320px;
}

.main-footer .footer-form {
    margin-bottom: 10px;
}

.mini-footer-web {
    font-family: "DM Sans" !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #161519;
    gap: 10px;
    min-height: 80px;
}

.mini-footer-web h1 {
    color: #fff;
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.75);
}

.mini-footer-web a {
    color: #fff;
    font-family: "DM Sans" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.75);
}

.mini-footer-web a {
    margin: 0px 11px;
}

.web-lines {
    position: absolute;
    margin-top: -100px;
    border-bottom: 0.2px solid rgba(144, 145, 156, 0.2);
    width: 78%;
}

.footer-form {
    min-width: 320px;
}

.ant-back-top {
    left: 50px !important;
    bottom: 50px !important;
}

.ant-back-top button {
    cursor: pointer;
    font-family: "DM Sans";
    background-color: cadetblue;
    color: #fff;
    border-radius: 50%;
    font-weight: 500;
    height: 50px;
    width: 50px;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ant-checkbox-wrapper span {
    color: #91929b;
    font-size: 13px;
}

.footer-form .ant-input {
    background: rgba(144, 145, 156, 0.08);
    border-radius: 6px;
    border-radius: 5px;
    height: 37px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 420;
    border: none;
    color: #fff;
}

.footer-form .ant-input::placeholder {
    color: rgb(106, 106, 106);
}

.footer-btn-web {
    position: absolute !important;
    background: transparent;
    border-radius: 6px;
    margin-top: -62px;
    margin-left: 250px;
    height: 37px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 450;
    font-size: 13px;
    line-height: 25px;
    color: #fff;
    border: none;
}

@media only screen and (max-width: 600px) {

    .footer-container,
    .main-footer,
    .mini-footer-web {
        flex-direction: column;
        padding: 20px;
    }

    .footer-container {
        min-height: 450px;
    }

    .footer-container h1 {
        font-size: 40px;
    }

    .footer-container .contact-button a {
        position: relative;
        top: 0px;
    }

    .footer-container .contact-buttonss {
        position: relative;
        top: 0px;
    }

    .footer-container p {
        position: relative;
        top: 0px;
    }

    .ant-back-top {
        display: none;
    }

    .main-footer {
        min-height: 500px;
    }

    .mini-footer-web a {
        margin: 0px 5px;
    }
}

@media (min-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */

    .footer-container .footer-band {
        width: 850px;
    }

    .mini-footer-web .mini-right {
        min-width: 520px;
    }

    .footer-btn-web {
        position: absolute !important;
        margin-top: -62px;
        margin-left: 280px;
    }
}

@media (min-width: 1281px) {
    /* hi-res laptops and desktops */
}

/* web  header */