html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins' !important;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: #121212;
    font-size: 14px;
}

body {
    overflow-x: hidden;
}

.txt-center {
    text-align: center;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}

.text-right {
    text-align: right;
    padding-right: 20px;
}

.client {
    width: 100% !important;
}

.clients {
    width: 30%;
}

.hen {
    padding-bottom: 50px;
}

.pointer {
    cursor: pointer;
}

.service-view-banner,
.service-view-welcome,
.service-view-service,
.service-view-start,
.service-view-flag {
    padding: 80px;
}

.loader {
    text-align: center;
}

.loader img {
    width: 70%;
}

.ant-form-item-explain-error {
    font-size: 11px;
    margin-top: 2px;
    color: red;
    /* white-space: nowrap !important; */
}

.domain-name .ant-form-item-explain-error {
    font-size: 12px;
    margin-top: 2px;
    color: #fff;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.table-loading img {
    width: 50%;
    position: absolute;
    left: 25%;
    top: 32%;
    mix-blend-mode: color-burn;
}

.table-loadings img {
    width: 30%;
    position: absolute;
    left: 35%;
    top: 25%;
    mix-blend-mode: color-burn;
}

.no-data {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    left: 35%;
    top: 55%;
}

.no-datas {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    left: 45%;
    top: 50%;
}

.no-data-setting {
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    left: 30%;
    top: 50%;
}

.ant-input-textarea-show-count::after {
    font-size: 12px;
    margin-top: 5px;
}










/* body part */

.service-main-page {
    padding-top: 25px !important;
    padding: 30px;
    padding-bottom: 20px !important;
}

.service-main-page h2 {
    font-weight: 600;
}

.service-main-page h2 span {
    font-weight: 600;
    font-size: 12px;
    color: #3157F6;
}

.service-body {
    /* background: #F8F9FD 0% 0% no-repeat padding-box */
    background: #f8f8f8 0% 0% no-repeat padding-box;
    width: 100%;
}

.service-main-page h4 {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 500;
}

/* body part */











/* employee page */

.service-main-title {
    padding-top: 30px;
}

.border-img {
    border: 1px solid #c7c4c4;
    box-shadow: 0px 0px 1px #00000029 !important;
    border-radius: 3px !important;
    padding: 9px;
    cursor: pointer;
    width: 33px;
}

.table-main {
    margin-top: 20px;
}


.table-main .ant-card-body {
    min-height: 470px;
}

.table-mains {
    margin-top: 15px;
}

.table-main .input[type='text'] {
    font-size: 10px !important;
}

.common-table {
    width: 100%;
}

.common-table thead th {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 0.3px solid #8b888829;
    padding-bottom: 10px;
    white-space: nowrap;
    padding-right: 20px;
}

.common-table tbody tr td {
    font-size: 11px;
    padding-top: 20px;
    white-space: nowrap;
    padding-right: 20px;
}

.ant-badge-status-text {
    font-size: 11px;
    white-space: nowrap;
    padding-right: 20px;
}

.ant-badge-status {
    line-height: 0px;
}

.table-main .ant-card {
    box-shadow: 0px 0px 1px #00000029;
    border-radius: 4px;
}

.ant-pagination {
    margin-top: 20px;
    font-family: 'poppins';
}

.ant-pagination-prev {
    position: absolute;
    left: 0px;
}


.ant-pagination-next {
    position: absolute;
    right: 0px;
}

.ant-pagination .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #AAAAAA;
    box-shadow: 0px 0px 1px #00000029;
    border-radius: 3px;
    font-size: 12px !important;
}


.border-page {
    border: 1px solid #AAAAAA;
    box-shadow: 0px 0px 1px #00000029 !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 12px;
    border-radius: 3px;
    font-family: 'poppins';
}

.ant-pagination-item {
    border: 1px solid #AAAAAA;
    box-shadow: 0px 0px 1px #00000029;
    border-radius: 3px;
}

.ant-pagination-item-active,
.ant-pagination-item-active:hover {
    border-color: #3157F6 !important;
    background-color: #3157F6;
    box-shadow: 0px 0px 1px #00000029;
}

.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
    color: #fff;
    font-family: "poppins" !important;
}

.service-main-title Button {
    font-size: 12px;
    width: 180px;
    height: 35px;
    background-color: #3157F6;
    color: #fff;
    border-radius: 4px;
}

.service-main-title .ant {
    width: 15px;
}


.service-main-title .ant-input-affix-wrapper {
    box-shadow: 0px 0px 1px #00000029 !important;
    border-radius: 3px !important;
}

.common-main {
    overflow-x: auto;
}

.blue {
    color: #3157F6 !important;
    text-decoration: underline;
    cursor: pointer;
}

.red {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

.s3-svg {
    width: 30px !important;
    height: 30px !important;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 6px;
    margin-bottom: 10px;
}

.ant-skeleton-avatar {
    width: 30px !important;
    height: 30px !important;
    border-radius: 3px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    z-index: 99 !important;
}

/* employee page */








/* Add Drawer start */

.ant-drawer-header,
.ant-modal-header,
.ant-modal-footer,
.ant-modal-close-x {
    display: none;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
    border-radius: 15px 0px 0px 0px;
}

.ant-modal-body {
    padding: 25px !important;
    margin-top: 100px;
}

.status-tickets .ant-modal-body {
    padding: 25px !important;
    margin-top: 0px;
}

.ant-modal-content {
    border-radius: 10px !important;
}


.drawer-head {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
    max-width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-drawer-content {
    border-radius: 15px 0px 0px 0px;
}

.ant-drawer-content-wrapper .ant-input {
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    font-size: 13px !important;
}

.ant-drawer-content-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

.ant-drawer-content-wrapper .ant-input-affix-wrapper {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

.ant-drawer-content-wrapper .ant-input-affix-wrapper span {
    font-size: 12px;
    color: grey;
}

.ant-popover-inner-content {
    padding: 15px !important;
}

.drop-data h4 {
    font-size: 13px !important;
    font-weight: 600;
}

.drop-data .ant-form-item-label label {
    font-size: 12px !important;
    font-weight: 600;
}

.drop-data span {
    font-size: 12px !important;
}

.drop-data .ant-form-item {
    margin-bottom: 0px !important;
    margin-top: 10px;
}

.drop-data .service-btn {
    background-color: #3157F6;
    border: 1px solid #3157F6;
    color: #fff;
    border-radius: 3px;
}

.drop-data .reset {
    background-color: #fff;
    border: 1px solid #3157F6;
    color: #3157F6;
    border-radius: 3px;
}

.drop-data .button-part {
    padding-top: 80px
}

/* .ant-popover {
    width: 200px !important;
} */

.drop-data .ant-select-selector {
    box-shadow: 0px 0px 1px #00000029 !important;
    border-radius: 3px !important;
}

.service-main-page input[type='text'] {
    font-size: 12px !important;
}

.split-dra .service-btn {
    position: none !important;
    bottom: -70px !important;
    width: 100%;
}


.split-dra .ant-form-item-label label {
    font-size: 12.5px;
    font-weight: 500;
}

.split-dra .ant-form-item {
    margin-bottom: 20px;
}

.split-dra .ant-form-item-control {
    border-radius: 3px !important;
}

.split-dra .service-btn {
    font-size: 12px;
    background-color: #3157F6;
    color: #fff;
    border-radius: 3px;
}


.split-dra input[type='text'] {
    font-size: 12px !important;
}

.split-dra .ant-select {
    font-size: 11px !important;
    border-radius: 3px;
}

.ant-picker {
    width: 100%;
    border-radius: 3px;
    font-size: 12px !important;
}

.size {
    width: 13px;
    margin-right: 8px;
}

.ant-picker-input>input {
    font-size: 12px;
    height: 22px;
}

.ant-form-item-control-input-content {
    /* border: 1px solid red; */
}

#editemployee_info .ant-form-item {
    margin-bottom: 18px !important;
}

.cancel-btn {
    background-color: #6d6d6d;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
}

.profile-bottom-btn {
    position: absolute;
    bottom: 20px;
}

/* Add Drawer End */












/* Edit Drawer start */

.ant-drawer-header-title {
    display: flex;
    flex: 1 1;
    align-items: center;
    min-width: 0;
    min-height: 0;
    flex-direction: row-reverse;
}

.ant-drawer-title {
    font-size: 14px;
    font-weight: 600;
}

.drawer .ant-form-item-label label {
    font-size: 12.5px;
    font-weight: 500;
}

.drawer .ant-form-item {
    margin-bottom: 20px;
}

.drawer .ant-form-item-control {
    border-radius: 3px !important;
}

.drawer .service-btn {
    font-size: 12px;
    background-color: #3157F6;
    color: #fff;
    border-radius: 3px;
    width: 100%;
}

.footer-button {
    position: absolute;
    bottom: 0px;
    width: 88% !important;
    background-color: #fff !important;
}


.drawer input[type='text'] {
    font-size: 12px !important;
}

.drawer .ant-select {
    font-size: 11px !important;
    border-radius: 3px;
}


.edit-from h4,
.edit-from h3 {
    font-size: 13px;
    line-height: 35px;
}

.edit-from h4 {
    font-size: 12px;
    color: #000;
    font-size: 500;
    max-width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.edit-from h3 {
    font-size: 12px;
    line-height: 35px;
    color: #AAAAAA;
    font-size: 500;
}

.edit-from .ant-form-item-label label {
    font-size: 12.5px;
    font-weight: 500;
    margin-top: 10px;
}

.edit-from .ant-select {
    font-size: 11px !important;
    border-radius: 3px;
}

.edit-from Button {
    font-size: 12px;
    background-color: #3157F6;
    color: #fff;
    border-radius: 3px;
}

.edit-from .reset {
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #3157F6;
    color: #3157F6;
    border-radius: 3px;
}

.edit-from .button-part .ant-form-item {
    margin-bottom: 0px !important;
    margin-top: 20px;

}


.edit-from .button-part {
    position: relative;
    bottom: -190px !important;
    width: 100%;
}

.button-part img {
    width: 12px;
    margin-right: 10px;
}

.button-part-employee .service-btn img {
    width: 13px;
    margin-right: 10px;
}

.button-part-employee {
    background-color: #fff !important;
    margin-top: 25px;
}

.drawer .reset {
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #3157F6;
    color: #3157F6;
    border-radius: 3px;
}

.space-btn {
    margin-top: -60px;
}


/*  2 file out */

.size_logos {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50% !important;
}

.body-size .ant-modal-content {
    width: 600px !important;
    left: -100px !important;
    padding: 50px;
    top: 100px;
}

.body-size .ant-modal-body {
    margin-top: 0px !important;
}

.body-size h1 {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    margin-left: -20px !important;
}

/*  2 file out*/


/* Edit Drawer End */











/* order page start */

.service-main-title h1 {
    font-size: 17px;
    font-weight: 600;
}

.service-main-title p a {
    font-size: 13px;
    color: #6d6d6d;
    font-family: "poppins" !important;
}

.service-main-title p {
    padding-top: 3px;
}

.order-form .ant-form-item-label label {
    font-size: 12px;
    font-weight: 500;
    color: #000 !important;
}

.order-form .ant-select {
    font-size: 12px !important;
}

.ant-select-selection-search-input {
    color: red !important;
}

.order-card {
    padding-top: 10px;

}

.order-card .ant-card .ant-card-body {
    padding: 20px !important;
    padding-bottom: 0px !important;
}

.ant-card {
    box-shadow: 0px 0px 1px #00000029;
    border-radius: 3px;
}

/* order page end */





/*  Mobile head start */
.aro a {
    color: #000;
    font-size: 20px;
    font-family: "poppins" !important;
}

.ar {
    color: #000;
    font-size: 25px;
    padding-left: 10px;
}

.aro {
    padding-top: 2px;
}

.arl {
    padding-left: 20px;
}

/*  Mobile head start */




/* scrollbar start*/

::-webkit-scrollbar {
    width: 3px;
    display: none;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #3157F6;
    border-radius: 10px;
}

/* scrollbar End*/




/*  confirm popup End */
.pop-up-text {
    text-align: center;
    font-size: 15px;
}

.pop-up-check {
    margin-top: 20px;
}

.pop-up-check .ant-form-item {
    margin-bottom: 0px !important;
}

.pop-up-check .ant-form-item {
    margin-bottom: 0px !important;
}

/*  confirm popup End */





/*  Common tab & table design */


.common-tab {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
}

.common-tab a {
    color: grey;
    font-size: 13px;
    margin-right: 20px;
    padding-bottom: 12px;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 99;
    font-weight: 500 !important;
    position: relative;
    font-family: "poppins" !important;
}

.common-tab .hrs {
    width: 83%;
    border-bottom: 1px solid #a9a8a8;
    margin-top: 1px;
}

.common-tab .hrs-setting {
    width: 100%;
    border-bottom: 1px solid #a9a8a8;
    margin-top: 11px;
}

.tab-active {
    color: #3157F6 !important;
    font-weight: 500;
    border-bottom: 3px solid #3157F6;
}

#tab-active {
    color: #3157F6 !important;
    font-weight: 500;
    border-bottom: 3px solid #3157F6;
}


.common-tab .ant {
    width: 13px;
}

.common-tab .ant-input-affix-wrapper {
    border: 1px solid #AAAAAA;
    box-shadow: 0px 0px 1px #00000029 !important;
    border-radius: 4px;
}

.add-btn {
    background-color: #3157F6;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
}


.ticket-table {
    overflow-x: auto;
    margin-top: 10px;
    height: 480px !important;
}

.ticket-table table {
    width: 100%;
}

.ticket-table table thead {
    background-color: #f0f0f0;
    height: 45px !important;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.ticket-table table thead tr {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 10px;
    white-space: nowrap;
}

.ticket-table table thead tr .anticon {
    font-size: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.ticket-table table thead tr th {
    font-weight: 500 !important;
    color: grey;
    padding-right: 20px;
    padding-left: 30px !important;
}

.ticket-table table tbody tr td {
    font-weight: 400 !important;
    color: #000;
    padding-right: 20px !important;
    font-size: 11px;
    padding: 10px;
    padding-left: 30px !important;
    padding-top: 14px;
    padding-bottom: 14px;
    white-space: nowrap;
    max-width: 100px !important;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    cursor: pointer;
}

.ticket-table table tbody tr td img {
    width: 12px;
    margin-right: 5px;
}

.ticket-table table tbody tr:hover {
    background-color: #efefef;
}


/*  Common tab & table design */


.profile-logo {
    width: 140px;
}

.domain-class {
    padding-top: 25px;
    padding-bottom: 25px;
}

.domain-class p {
    font-size: 16px;
    color: #3157F6;
    font-weight: 500;
    margin-top: 20px;
}

.domain-class p span {
    font-size: 16px;
    font-weight: 500;
    color: #6d6d6d;
}

Button {
    white-space: nowrap;
}

.ant-input-affix-wrapper>.ant-input {
    font-size: inherit !important;
    border: none !important;
    outline: none !important;
}

iframe {
    /* pointer-events: none !important; */
    border: none !important;
}

.ant-input:focus,
.ant-input-focused {
    border-color: none !important;
    box-shadow: none !important;
    border-right-width: 1px !important;
    outline: 0 !important;
}

.ant-input:hover {
    border-color: none !important;
    border-right-width: 1px !important;
}

.loader-img {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
}

.loader-img h1 {
    font-size: 17px;
    font-weight: 600;
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-not {
    text-align: center;
}

.page-not h1 {
    font-weight: 600;
    font-size: 40px;
    position: relative;
    top: 150px;
    margin-right: 500px;
}