.licence a {
    padding: 10px 40px;
    font-weight: 600;
    color: rgb(105, 105, 105) !important;
    font-size: 13px;
}

.lic-active {
    background-color: #fff !important;
    padding: 10px 40px;
    border-radius: 50px;
    font-weight: 600;
    color: #000 !important;
}


.licence {
    display: flex;
    justify-content: center;
    gap: 20px;
}


.licence-head {
    background-color: #ededed;
    padding: 10px 1.5px;
    border-radius: 30px;
}

.tax h5 {
    color: grey;
    padding: 10px;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 50px;
}

.contact {
    margin-top: 14px;
}






.licence-card {
    padding-left: 100px;
    padding-right: 100px
}

.licence-card .ant-card {
    border-radius: 30px;
    padding: 20px !important;
    min-height: 445px;
}

.licence-card .ant-card .ant-card-body {
    border-radius: 30px;
    padding: 0px !important;
}

.licence-card h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.licence-card .ant-card ul li {
    font-size: 12px;
    color: grey;
    line-height: 20px;
}

.licence-icon {
    height: 120px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-content: space-around;
}

.licence-details h1 {
    text-align: center;
    margin-top: 30px;
}

.licence-details h1 .licence-rupee {
    font-size: 13px !important;
    position: relative;
    top: -12px;
    left: -5px;
}

.licence-details h1 .licence-month {
    font-size: 12px !important;
}

.licence-details h3 {
    text-align: center;
    font-size: 28px;
    margin-top: -25px;
}

.licence-details h3 .licence-ru {
    font-size: 13px !important;
    position: relative;
    top: -12px;
    left: -5px;
}

.licence-details h3 .licence-mo {
    font-size: 12px !important;
}

.licence-details h6 {
    font-size: 11px !important;
    text-align: center;
    position: relative;
    top: -23px;
    color: grey;
    font-weight: 400 !important;
}


.licence-card .blue-back {
    background-color: blue;
    border-radius: 30px;
    padding: 5px;
    margin-top: -40px;
}

.licence-card .blue-back h4 {
    text-align: center;
    color: #fff;
    margin-bottom: 5px;
    margin-top: 5px;
}






.contact .ant-card {
    height: 210px;
    border-radius: 30px;
}

.contact h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.contact p {
    font-size: 11px;
    color: grey;
}

.contact .ant-btn {
    background-color: blue;
    color: #fff;
    border-radius: 20px;
    font-size: 12px;
}

.button-split .ant-btn {
    background-color: blue;
    color: #fff;
    border-radius: 20px;
    font-size: 12px;
    margin: 10px;
    margin-top: 0px;
    width: 120px;
    position: absolute;
    bottom: 30px;
    left: 55px;
}