/* web  header */

.service-view-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-bottom: 0.2px solid rgba(144, 145, 156, 0.2);
    position: sticky;
    top: 0px;
    z-index: 999;
}

.service-view-header .web-logo .logo {
    width: 100px;
}

.service-view-header .web-links a {
    padding: 10px;
    font-size: 13.5px;
    color: #8da0b0;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.service-view-header .web-auth a {
    padding: 10px;
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.service-view-header .web-links .nav-active {
    color: #283742;
    font-weight: 500 !important;
}

.service-view-header .black-btn {
    background-color: #111013;
    padding: 5px 12px;
    gap: 6px;
    color: #fff;
    border-radius: 5px;
}

.service-view-header .black-btn:hover {
    background-color: cadetblue;
}

/*  Web Header Start */

.mobile-view-only h1 {
    font-size: 12px;
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 0px;
    letter-spacing: 0.01em;
    margin-top: 8px;
    margin-left: 10px;
    cursor: pointer;
}

.dra ul li {
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .service-view-header .web-links a {
        display: none;
    }

    .service-view-header {
        position: relative !important;
        padding: 20px 10px;
    }
}

@media only screen and (min-width: 600px) {
    .service-view-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:801px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .service-view-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .service-view-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    .service-view-header .mobile-view-only {
        display: none;
    }
}

/* web  header */