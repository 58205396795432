.add-btn {
    background-color: #3157F6;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
}

.blue-btn {
    background-color: #42C6FF;
    border: none;
    min-width: 70px;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
}


.green-btn {
    background-color: #60CD5E;
    border: none;
    color: #fff;
    min-width: 70px;
    font-size: 11px;
    border-radius: 4px;
    cursor: pointer;
}

.red-btn {
    background-color: #FE5151;
    border: none;
    min-width: 70px;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    cursor: pointer;
}

.yellow-btn {
    background-color: #a4de2e;
    border: none;
    min-width: 70px;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
}

.grey-blue-btn {
    background-color: #fff;
    color: red;
    border-radius: 4px;
    font-size: 11px;
    border: 1px solid #f7f7f7;
}

.grey-btn {
    background-color: #fff;
    color: grey !important;
    border-radius: 4px;
    font-size: 11px;
    border: 1px solid #f7f7f7;
}


.back-blue {
    border: 1px solid #d3d3d3;
    box-shadow: 0px 0px 1px #00000029 !important;
    border-radius: 4px;
    min-width: 75px;
    color: #3157F6;
    font-weight: 500;
    font-size: 11px;
    border-radius: 4px;
    background-color: transparent !important;
    cursor: pointer;
}

.back-grey {
    border: 1px solid #d3d3d3;
    box-shadow: 0px 0px 1px #00000029 !important;
    border-radius: 4px;
    color: #AAAAAA;
    min-width: 75px;
    font-weight: 500;
    font-size: 11px;
    border-radius: 4px;
    background-color: transparent !important;
    cursor: pointer;
}

.blue-line {
    color: #3157F6 !important;
    text-decoration: underline !important;
}

.red-line {
    color: red !important;
    text-decoration: underline !important;
}



.yes-btn {
    margin-bottom: 0px !important;
    background-color: #3157F6;
    border-radius: 3px;
    color: #fff;
    border: 1px solid #3157F6;
}



.no-btn {
    margin-bottom: 0px !important;
    background-color: grey;
    color: #fff;
    border: 1px solid grey;
    border-radius: 3px;
}

.ant-modal {
    max-width: 320px !important;
}


.one-btn {
    background-color: rgb(29, 197, 192);
    border-radius: 4px !important;
    font-size: 11px !important;
    color: #fff !important;
    width: 83px !important;
    border: 1px solid #f7f7f7 !important;
}

.two-btn {
    background-color: rgb(14, 139, 14);
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.three-btn {
    background-color: #FE5151;
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.four-btn {
    background-color: rgb(238, 201, 80);
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.five-btn {
    background-color: rgb(193, 57, 234);
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.six-btn {
    background-color: #42C6FF;
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.seven-btn {
    background-color: rgba(6, 199, 6, 0.733);
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.service-blue {
    background-color: #3157F6;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
    position: absolute !important;
    bottom: -180px;
}

.service-red {
    background-color: #fff;
    color: #3157F6;
    text-align: center;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.ten-btn {
    background-color: rgb(235, 25, 175);
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}

.eleven-btn {
    background-color: rgb(226, 98, 34);
    border-radius: 4px;
    font-size: 11px;
    color: #fff;
    width: 83px;
    border: 1px solid #f7f7f7;
}


.cancel-blue {
    background-color: #fff;
    color: #3157F6;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
    position: absolute !important;
    bottom: -180px;
}


.service-bluez {
    background-color: #3157F6;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
    position: absolute !important;
    bottom: -320px;
}


.cancel-bluez {
    background-color: #fff;
    color: #3157F6;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
    position: absolute !important;
    bottom: -320px;
}

.cancel-bluez:hover {
    background-color: #fff;
    color: #3157F6;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
    position: absolute !important;
    bottom: -320px;
}

.one-blue {
    background-color: #3157F6;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
}

.two-blue {
    background-color: #fff;
    color: #3157F6;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid #3157F6;
}

.fixed-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}